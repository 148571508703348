<template>
  <div class="card mb-3 shadow-sm sticky-top">
    <div class="card-body">
      <!-- Tab Navigation -->
      <ul class="nav nav-pills align-items-center" id="pills-tab" role="tablist">
        <!-- General Information -->
        <li class="nav-item col-12 col-md-auto mb-2 mb-md-0" role="presentation">
          <a
            href="#general-info"
            class="nav-link active"
            id="general-info-tab"
            data-toggle="pill"
            role="tab"
            aria-controls="general-info"
            aria-selected="true"
          >
            <i class="far fa-list-alt" v-if="errors && !errors.length"></i>
            <i class="fas fa-exclamation-triangle text-warning" v-else></i>
            <span class="ml-1">Genel Bilgiler</span>
          </a>
        </li>
        <!-- ./General Information -->

        <!-- Equipment Details -->
        <li class="nav-item col-12 col-md-auto mb-2 mb-md-0" role="presentation">
          <a
            href="#seo-detail"
            class="nav-link"
            id="seo-detail-tab"
            data-toggle="pill"
            role="tab"
            aria-controls="seo-detail"
            aria-selected="false"
          >
            <i class="fas fa-rocket"></i>
            <span class="ml-1">SEO Ayarları</span>
          </a>
        </li>
        <!-- ./Equipment Details -->

        <!-- Submit Button -->
        <li class="nav-item ml-md-auto mx-auto mx-md-0">
          <WeSubmitButton
            parent-class="text-center"
            v-bind:submit-status="submit"
            v-bind:update="$route.params.id"
            v-on:submit="saveForm"
          />
        </li>
        <!-- ./Submit Button -->
      </ul>
      <!-- Tab Navigation -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "Navigation",
  props: {
    submit: {
      default: false,
    },
    errors: {
      default: "",
    },
  },
  methods: {
    saveForm() {
      this.$emit("save-form");
    },
  },
};
</script>
