<template>
  <WeContainer card="" v-if="ready">
    <Navigation
      v-on:save-form="saveForm"
      v-bind:submit="submit"
      v-bind:errors="errors"
    />
    <Content ref="content" />
    <WeSeoBar
      v-bind:focus-keyword="equipment.info.manufacturer"
      v-bind:metaTitle="equipment.detail.meta.title"
      v-bind:metaDescription="equipment.detail.meta.description"
      v-bind:description="equipment.detail.description.text"
      v-bind:slug="getSlug"
    />
  </WeContainer>
  <WeLoading v-else />
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import Navigation from "./Navigation/Index";
import Content from "./Content/Index";

export default {
  name: "Detail",
  data() {
    return {
      submit: false,
      ready: false,
      errors: [],
    };
  },
  components: {
    Navigation,
    Content,
  },
  methods: {
    ...mapActions("equipment", ["create", "update", "load", "getById"]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    validateForm() {
      this.errors = [];

      let informationValid = this.$refs.content.$refs.information.$v;
      informationValid.$touch();

      if (informationValid.$invalid) {
        this.errors.push("info");
      }

      return this.formAction();
    },
    formAction() {
      if (this.errors && this.errors.length) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });
        return false;
      } else {
        return true;
      }
    },
    getEquipmentById() {
      if (this.$route.params.id) {
        this.getById({
          id: this.$route.params.id,
          onSuccess: (result) => {
            this.ready = true;
          },
        });
      } else {
        this.ready = true;
      }
    },
    addEquipment() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;
      this.create({
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showAlert(result, this.redirectToList);
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    updateEquipment() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;
      this.update({
        id: this.$route.params.id,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showAlert(result, this.redirectToList);
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    redirectToList() {
      this.$router.push("/equipments");
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
    saveForm() {
      if (this.$route.params.id) {
        this.updateEquipment();
      } else {
        this.addEquipment();
      }
    },
  },
  computed: {
    ...mapState(["shared", "equipment"]),
    getSlug() {
      return helper.slugify(this.equipment.info.manufacturer);
    },
  },
  mounted() {
    this.load();
    this.getEquipmentById();
  },
};
</script>
