<template>
  <!-- General Information -->
  <div
    class="tab-pane fade show active"
    id="general-info"
    role="tabpanel"
    aria-labelledby="general-info-tab"
  >
    <!-- Tab Content -->
    <div class="row align-items-stretch mb-3">
      <!-- MAIN CONTENT -->

      <div class="col-12 col-xl">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="custom-card-title">
              <i class="far fa-list-alt"></i>
              <span>GENEL BİLGİLER</span>
            </h5>
            <hr />
            <div class="form-row">
              <div class="col-12 col-md ">
                <!-- Manufacturer Name -->
                <div class="form-group mb-3">
                  <WeInput
                    label="Ekipman Üreticisi"
                    name="manufacturer"
                    placeholder="Ekipman Üreticisi"
                    v-bind:required="true"
                    v-model="manufacturer"
                    v-bind:error="$v.manufacturer.$error"
                  />
                </div>
                <!-- ./Manufacturer Name -->
              </div>
              <div class="col-12 col-md">
                <!-- Stock Code -->
                <div class="form-group">
                  <WeInput
                    label="Ekipman Kodu"
                    name="equipment_code"
                    placeholder="Ekipman Kodu"
                    v-bind:required="true"
                    v-model="equipmentSKU"
                    v-bind:error="$v.equipmentSKU.$error"
                  />
                </div>
                <!-- ./Stock Code -->
              </div>
              <div class="col-12 col-md">
                <!-- Engine Brand -->
                <div class="form-group">
                  <WeInput
                    label="Motor Markası"
                    name="engine_brand"
                    placeholder="Motor Markası"
                    v-model="engineBrand"
                  />
                </div>
                <!-- ./Engine Brand -->
              </div>
              <div class="col-12 col-md">
                <!-- Engine Model -->
                <div class="form-group">
                  <WeInput
                    label="Motor Modeli"
                    name="engine_model"
                    placeholder="Motor Modeli"
                    v-model="engineModel"
                  />
                </div>
                <!-- ./Engine Model -->
              </div>
              <div class="col-12 col-md">
                <!-- Engine Year -->
                <div class="form-group">
                  <WeInput
                    label="Motor Yılı"
                    name="engine_year"
                    placeholder="Motor Yılı"
                    v-bind:required="false"
                    v-model="equipment.info.engine.year"
                  />
                </div>
                <!-- ./Engine Year -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ./MAIN CONTENT -->
    </div>
    <!-- ./Tab Content -->

    <!-- Equipment Products Section -->
    <Products v-model="equipmentProducts" />
    <!-- ./Equipment Products Section -->
  </div>
  <!-- ./General Information -->
</template>
<script>
import Products from "./views/Products/Index";
import { mapActions, mapMutations, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Information",
  validations: {
    equipmentSKU: {
      required,
    },
    manufacturer: {
      required,
    },
    equipmentProducts: {
      required: function() {
        return this.equipment.info.products.length;
      },
    },
  },
  methods: {
    ...mapMutations("equipment", ["appendProduct", "changeCurrencySymbol"]),
    updateCurrentPrice(data) {
      this.equipment.info.normalPrice = data.totalPrice;
      this.equipment.currencySymbol = data.currency.symbol;
    },
  },
  components: {
    Products,
  },
  computed: {
    ...mapState(["equipment"]),
    engineModel: {
      get() {
        return this.equipment.info.engine.model;
      },
      set(value) {
        this.equipment.info.engine.model = value;
      },
    },
    engineBrand: {
      get() {
        return this.equipment.info.engine.brand;
      },
      set(value) {
        this.equipment.info.engine.brand = value;
      },
    },
    manufacturer: {
      get() {
        return this.equipment.info.manufacturer;
      },
      set(value) {
        this.equipment.info.manufacturer = value;
      },
    },
    equipmentSKU: {
      get() {
        return this.equipment.info.sku_no;
      },
      set(value) {
        this.equipment.info.sku_no = value;
      },
    },
    equipmentProducts: {
      get() {
        return this.equipment.info.products;
      },
      set(value) {
        this.equipment.info.products = value;
      },
    },
  },
};
</script>
