<template>
  <div class="tab-content">
    <Information ref="information" />
    <Detail />
  </div>
</template>

<script>
import Information from "./views/Information/Index";
import Detail from "./views/Detail/Index";

export default {
  name: "Content",
  components: {
    Information,
    Detail,
  },
};
</script>
