<template>
  <!-- Product Detail -->
  <div
    class="tab-pane fade"
    id="seo-detail"
    role="tabpanel"
    aria-labelledby="seo-detail-tab"
  >
    <!-- Tab Content -->
    <div class="row">
      <!-- MAIN CONTENT -->
      <div class="col-12 mb-3 col-xl mb-xl-0">
        <div class="card mb-3">
          <div class="card-body">
            <WeDescription
              v-model="equipment.detail.description"
              v-bind:access-token="session.accessToken"
              v-bind:name="'equipment'"
              compact="true"
              v-bind:title="$t('description')"
            />

            <WeMetaData v-model="equipment.detail.meta" />
          </div>
        </div>
      </div>
      <!-- ./MAIN CONTENT -->
    </div>
    <!-- ./Tab Content -->
  </div>
  <!-- ./Product Detail -->
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  name: "Detail",
  methods: {
    ...mapActions("equipment", ["getModelList"]),
  },
  computed: {
    ...mapState(["equipment", "session"]),
  },
};
</script>
