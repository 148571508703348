<template>
  <div>
    <div class="row">
      <div class="col-12 col-lg">
        <div class="mb-3" id="cross-product-accordion">
          <div class="card">
            <!-- Accordion Header -->
            <div class="card-header p-0" id="cross-product-head">
              <h2 class="mb-0">
                <button
                  class="btn btn-white btn-block text-left p-3"
                  type="button"
                  data-toggle="collapse"
                  data-target="#cross-product-collapse"
                  aria-expanded="true"
                  aria-controls="cross-product-collapse"
                >
                  <h5 class="custom-card-title">
                    <i class="fas fa-random"></i>
                    <span>ÇAPRAZ ÜRÜNLER</span>
                    <i
                      class="fas fa-chevron-down float-right font-14 mt-1 text-muted"
                    ></i>
                  </h5>
                </button>
              </h2>
            </div>
            <!-- Accordion Header -->

            <!-- Cross Product Accordion Body -->
            <div
              id="cross-product-collapse"
              class="collapse show"
              aria-labelledby="cross-product-head"
              data-parent="#cross-product-search"
            >
              <div class="card-body">
                <!-- Search Product -->
                <div class="position-relative" v-if="!selected.cross">
                  <input
                    type="text"
                    name="cross_product_search"
                    id="cross-product-search"
                    class="form-control"
                    placeholder="Çapraz Ürün Ara"
                    autocomplete="off"
                    v-model="searchData.cross"
                    v-on:input="onInput"
                    v-on:focus="onSearchCrossFocus"
                    v-on:blur="crossOnBlur"
                  />
                  <!-- ./Search Product -->

                  <!-- Result Count -->
                  <div
                    class="result-count"
                    v-if="searchData.cross && searchData.cross.length >= length"
                  >
                    <div v-if="!searchCrossReady">
                      <i class="fas fa-spinner fa-spin"></i>
                    </div>
                    <div v-else>
                      <div
                        v-if="
                          searchData.cross.length &&
                            filteredCrossProducts &&
                            filteredCrossProducts.length
                        "
                      >
                        <span class="badge badge-pill alert-primary"
                          >{{ filteredCrossProducts.length }} Sonuç</span
                        >
                      </div>
                      <div v-else>
                        <span class="badge badge-pill alert-dark"
                          >Sonuç Bulunamadı</span
                        >
                      </div>
                    </div>
                  </div>
                  <!-- ./Result Count -->

                  <div
                    class="dropdown-menu shadow-sm col-12 custom-scrollbar search-result"
                    v-bind:class="{
                      show: filteredCrossProducts.length && crossCardVisible,
                    }"
                  >
                    <span
                      class="btn dropdown-item py-2"
                      v-for="(product, i) in filteredCrossProducts"
                      v-bind:key="product.id + '-' + i"
                      v-on:click.prevent="selectCrossProduct(product)"
                    >
                      <template v-if="product.code"
                        >{{ product.code }} -
                      </template>
                      {{ product.name }}
                    </span>
                  </div>
                </div>
                <!-- Search Product -->

                <!-- Selected Cross Product -->
                <input
                  class="form-control"
                  v-else
                  v-bind:value="
                    selected.cross.code + ' - ' + selected.cross.name
                  "
                  v-on:click="clearSelectedCross"
                />
                <!-- ./Selected Cross Product -->
              </div>
            </div>
            <!-- ./Packet Product Accordion Body -->
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="mb-3" id="packet-product-accordion">
          <div class="card">
            <!-- Accordion Header -->
            <div class="card-header p-0" id="packet-product-head">
              <h2 class="mb-0">
                <button
                  class="btn btn-white btn-block text-left p-3"
                  type="button"
                  data-toggle="collapse"
                  data-target="#packet-product-collapse"
                  aria-expanded="true"
                  aria-controls="packet-product-collapse"
                >
                  <h5 class="custom-card-title">
                    <i class="fas fa-boxes"></i>
                    <span>PAKETTEKİ ÜRÜNLER</span>
                    <i
                      class="fas fa-chevron-down float-right font-14 mt-1 text-muted"
                    ></i>
                  </h5>
                </button>
              </h2>
            </div>
            <!-- Accordion Header -->

            <!-- Packet Product Accordion Body -->
            <div
              id="packet-product-collapse"
              class="collapse show"
              aria-labelledby="packet-product-head"
              data-parent="#packet-product-search"
            >
              <div class="card-body" id="packet-product-search">
                <div class="row align-items-center">
                  <div class="col">
                    <!-- Product Select Box -->
                    <v-select
                      placeholder="Ürün Ara"
                      label="name"
                      v-bind:value="null"
                      v-bind:options="crossProducts"
                      v-bind:disabled="crossProducts && !crossProducts.length"
                      v-model="selected.product"
                    >
                      <template slot="option" slot-scope="option">
                        <img
                          v-bind:src="option.image.path.thumb"
                          alt=""
                          v-if="option.image && option.image.path"
                        />
                        <img
                          v-else
                          src="https://www.samsung.com/etc/designs/smg/global/imgs/support/cont/NO_IMG_600x600.png"
                          width="50"
                          height="50"
                          alt=""
                        />
                        <span class="ml-3">{{ option.name }}</span>
                      </template>
                      <template slot="option" slot-scope="option">
                        <img
                          v-bind:src="option.image.path.thumb"
                          alt=""
                          v-if="option.image && option.image.path"
                        />
                        <img
                          v-else
                          src="https://www.samsung.com/etc/designs/smg/global/imgs/support/cont/NO_IMG_600x600.png"
                          width="50"
                          height="50"
                          alt=""
                        />
                        <span class="ml-3">{{ option.name }}</span>
                      </template>
                    </v-select>
                    <!-- ./Product Select Box -->
                  </div>
                  <div
                    class="col-auto"
                    v-if="selected.product && selected.product.id"
                  >
                    <span
                      class="btn btn-outline-success"
                      v-on:click="addProduct"
                    >
                      <i class="fas fa-check"></i> Seç
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- ./Packet Product Accordion Body -->
          </div>
        </div>
      </div>
    </div>
    <WeCard class="mb-3" v-if="computedData && computedData.length">
      <WeTable
        v-bind:index="false"
        v-bind:data.sync="computedData"
        v-bind:columns="columns"
        v-bind:actions="actions"
        v-bind:searchText="'Ürün Ara'"
        v-bind:paginatable="true"
        v-on:on-action="onAction"
        v-on:qty-change="onQtyChange"
      />
    </WeCard>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Products",
  data() {
    return {
      totalPrice: 0,
      currency: "₺",
      columns: [
        { name: "cross_name", th: "Çapraz Ürün", type: "string" },
        { name: "name", th: "Ürün Adı", type: "string" },
        { name: "alt_name", th: "Alternatif İsim", type: "input" },
        { name: "quantity", th: "Miktar", type: "quantity", width: "20" },
        { name: "price", th: "Fiyat", type: "currency" },
      ],
      actions: [
        {
          icon: "fas fa-trash fa-sm",
          class: "btn-outline-danger border-0 ml-2",
          action: "remove",
        },
      ],

      delay: 500,
      length: 3,

      searchData: {
        cross: null,
        product: null,
      },

      searchCrossReady: false,
      crossCardVisible: false,

      selected: {
        cross: null,
        product: "",
      },

      currentCrossProduct: null,
      filteredCrossProducts: [],
      crossProducts: [],
    };
  },
  props: {
    data: {
      default: null,
    },
  },
  model: {
    prop: "data",
  },
  computed: {
    computedData: {
      get: function() {
        return this.data;
      },
      set: function(value) {
        this.data = value;
      },
    },
  },
  methods: {
    ...mapActions("shared", ["searchProduct", "getCrossProducts"]),
    onInput(e) {
      this.doSearch(e.target.value, this.delay);
    },
    doSearch(text, timeout) {
      this.searchCrossReady = false;

      let localTimeout = 0;
      if (timeout) {
        localTimeout = timeout;
      }

      if (text && text.trim().length) {
        if (text.trim().length >= this.length) {
          this.emitSearch(text, localTimeout);
        }
      } else {
        this.clearCrossProducts();
      }
    },
    emitSearch(text, timeout) {
      clearTimeout(this.timer);
      this.crossOnBlur();
      this.timer = setTimeout(() => {
        clearTimeout(this.timer);

        this.searchProduct({
          type: "cross",
          query: text,
          onSuccess: (result) => {
            this.clearFilteredCrossProducts();

            helper.copy(result.data, this.filteredCrossProducts);
            this.searchCrossReady = true;
            this.crossCardVisible = true;
          },
        });
      }, timeout);
    },
    onSearchCrossFocus() {
      this.crossCardVisible = true;
      this.clearCrossProducts();
      this.selected.product = "";
    },
    crossOnBlur() {
      setTimeout(() => {
        this.crossCardVisible = false;
      }, 250);
    },
    clearFilteredCrossProducts() {
      this.filteredCrossProducts = [];
    },
    clearCrossProducts() {
      this.crossProducts = [];
    },
    clearSelectedCross() {
      this.selected.cross = "";
      this.clearCrossProducts();

      this.searchData.cross = this.selected.cross.name;
    },
    selectCrossProduct(product) {
      this.searchData.cross = "";

      this.clearFilteredCrossProducts();
      this.clearCrossProducts();

      this.selected.cross = product;
      this.getProductList();
    },
    getProductList() {
      this.getCrossProducts({
        id: this.selected.cross.id,
        onSuccess: (result) => {
          helper.copy(result.data, this.crossProducts);
        },
      });
    },
    addProduct() {
      let product = this.selected.product;
      let cp = this.selected.cross;

      product.cross_name = cp.code + " - " + cp.name;
      product.cross_id = cp.id;
      product._quantity = product.quantity + " " + product.quantity_type;
      product.alt_name = product.alt_name ? product.alt_name : null;

      this.computedData.push(product);
      this.selected.product = "";

      this.calculatePrice();
    },
    onAction(data) {
      let message =
        data.row.name + " adlı ürünü listeden çıkarmak istiyor musunuz ?";

      this.$swal({
        text: message,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.computedData.splice(data.index, 1);
          this.calculatePrice();
        }
      });
    },
    getRowIndexById(id) {
      return helper.arrayFind(this.computedData, "id", id);
    },
    onQtyChange(data) {
      // const rowIndex = data.row.index;
      // this.computedData[rowIndex].quantity = data.value;
      this.calculatePrice();
    },
    calculatePrice() {
      this.totalPrice = 0;

      if (this.computedData && this.computedData.length) {
        for (let i = 0; i < this.computedData.length; i++) {
          const element = this.computedData[i];
          this.totalPrice += Number(element.price * element.quantity);
          this.currency = {
            id: element.currency_code.id,
            code: element.currency_code.code,
            symbol: element.currency_code.symbol,
          };
        }
      }

      let data = {
        totalPrice: this.totalPrice,
        currency: this.currency,
      };

      this.$emit("total-price-changed", data);
      this.getTotalPrice();
    },
    getTotalPrice() {
      this.totalPrice = new Intl.NumberFormat(localization.locale.code, {
        style: "currency",
        currency: "EUR",
      }).format(this.totalPrice);
    },
  },
  mounted() {
    this.calculatePrice();
  },
};
</script>
<style lang="scss" scoped>
.search-result {
  max-height: 200px;
  overflow: auto;
}
.result-count {
  position: absolute;
  right: 10px;
  z-index: 1060;
  top: 7px;
}
</style>
